import { FilterTagWrapper } from "@/Components/Tags";
import { useAppState } from "@/modules/app/useAppContext";
import { useNavigate } from "react-router-dom";
import { QueryList } from "../Query/QueryList";
import { Paginate } from "@/Components/Paginate";
import { getAllQueries, getQueryFilters } from "@/helpers/backend_helper";
import { RelativeComponentLoader } from "@/Components/Loader";
import { useState } from "react";
import {
  getDateByEndOfDayWithoutTimeoffset,
  getDateByStartOfDayWithoutTimeoffset,
} from "@/Components/DateRange/utils";
import { useQuery } from "react-query";
import { GET_QUERY, GET_QUERY_FILTERS } from "../Query/constants";
import InsightLabel from "../Query/InsightLabel";
import useFiltersBySearchParams from "@/helpers/useFiltersBySearchParams";

type SortAttribute = "query_cost" | "end_time" | "execution_time";

export const WarehouseQueries: React.FC<{
  startDate: Date;
  endDate: Date;
  warehouse: string;
}> = ({ startDate, endDate, warehouse }) => {
  const {searchParams, deleteSearchParamsByKeyValue, updateSearchParamsByKey} = useFiltersBySearchParams();
  const { currencySymbol } = useAppState();
  const tagFilter = searchParams.getAll("tags") || [];
  const executionTimeFilter = searchParams.get("executionTime");
  const queryCostFilter = searchParams.get("queryCost");
  const userFilter = searchParams.getAll("users") || [];

  const [sortAttribute, setSortAttribute] = useState("query_cost");
  const [sortOrder, setSortOrder] = useState({
    query_cost: "",
    end_time: "",
    execution_time: "",
  });

  const executionTimeFilterMode =
    searchParams.get("executionTimeFilterMode") || "gt";
  const executionCostFilterMode =
    searchParams.get("executionCostFilterMode") || "gt";

  const handleCostSortChange = (so: string) => {
    if (so) {
      setSortAttribute("query_cost");
      setSortOrder((prevSortOrder) => ({
        ...prevSortOrder,
        query_cost: so,
      }));
    }
  };

  const handleTimestampSortChange = (so: string) => {
    if (so) {
      setSortAttribute("end_time");
      setSortOrder((prevSortOrder) => ({
        ...prevSortOrder,
        end_time: so,
      }));
    }
  };

  const handleExecutionTimeSortChange = (so: string) => {
    if (so) {
      setSortAttribute("execution_time");
      setSortOrder((prevSortOrder) => ({
        ...prevSortOrder,
        execution_time: so,
      }));
    }
  };

  const [page, setPage] = useState(0);
  const navigate = useNavigate();

  const getFilterParams = () => {
    const params: Record<string, string | number | string[]> = {
      page: page + 1,
      size: 8,
      warehouses: [warehouse],
    };
    if (tagFilter.length > 0) params.tags = tagFilter;
    if (userFilter.length > 0) params.users = userFilter;
    if (sortAttribute) {
      params.sortAttribute = sortAttribute;
      params.sortOrder = sortOrder[sortAttribute as SortAttribute];
    }
    if (executionTimeFilter) {
      params.executionTimeFilter =
        parseFloat(executionTimeFilter) * (60 * 1000);
      params.executionTimeFilterMode =
        executionTimeFilterMode === "lt" ? "lt" : "gt";
    }
    if (queryCostFilter) {
      params.executionCostFilter = queryCostFilter;
      params.executionCostFilterMode =
        executionCostFilterMode === "lt" ? "lt" : "gt";
    }
    params.start_date =
      getDateByStartOfDayWithoutTimeoffset(startDate).toISOString();
    params.end_date =
      getDateByEndOfDayWithoutTimeoffset(endDate)?.toISOString()!;
    return params;
  };

  const { data: filters, isLoading: isfiltersLoading } = useQuery({
    queryKey: [GET_QUERY_FILTERS],
    queryFn: () => getQueryFilters(),
  });

  const { data: queries, isLoading: isQueriesLoading } = useQuery({
    queryKey: [
      GET_QUERY,
      page,
      ...tagFilter,
      ...userFilter,
      sortOrder[sortAttribute as SortAttribute],
      sortAttribute,
      executionTimeFilter,
      queryCostFilter,
      startDate,
      endDate,
      executionTimeFilterMode,
      executionCostFilterMode,
    ],
    queryFn: () => getAllQueries(getFilterParams()),
  });

  const handleCostFilterChange = ({
    value,
    mode,
  }: {
    value: string;
    mode: string;
  }) => {
    updateSearchParamsByKey({
      queryCost: value,
      executionCostFilterMode: mode,
    });
  };

  const handleExecutionTimeFilterChange = ({
    value,
    mode,
  }: {
    value: string;
    mode: string;
  }) => {
    updateSearchParamsByKey({
      executionTime: value,
      executionTimeFilterMode: mode,
    });
  };

  const handleUserFilterChange = (value: string) => {
    updateSearchParamsByKey({"users": value});
  };

  const handleInsightsFilterChange = (value: string) => {
    updateSearchParamsByKey({"tags": value});
  };

  if (isQueriesLoading || isfiltersLoading) {
    return <RelativeComponentLoader label="Loading queries..." />;
  }

  return (
    <div>
      <div className="d-flex flex-wrap gap-sm mb-2">
        <FilterTagWrapper
          filters={{
            tagFilter: {
              filterStr: tagFilter,
              label: "Tags",
              onclose: (removedFilter: string) => {
                deleteSearchParamsByKeyValue({"tags": removedFilter});
              },
            },
            userFilter: {
              filterStr: userFilter,
              label: "Users",
              onclose: (removedFilter: string) => {
                deleteSearchParamsByKeyValue({"users": removedFilter});
              },
            },
            executionTimeFilter: {
              filterStr:
                executionTimeFilter &&
                `${
                  executionTimeFilterMode === "lt" ? "<" : ">"
                } ${executionTimeFilter}`,
              label: "mins",
              onclose: () => {
                deleteSearchParamsByKeyValue({"executionTime": undefined, "executionTimeFilterMode": undefined});                
              },
            },
            queryCostFilter: {
              filterStr:
                queryCostFilter &&
                `${
                  executionCostFilterMode === "lt" ? "<" : ">"
                } ${queryCostFilter}`,
              label: currencySymbol,
              onclose: () => {
                deleteSearchParamsByKeyValue({"queryCost": undefined, "executionCostFilterMode": undefined});
              },
            },
          }}
        />
      </div>
      <QueryList
        header={[
          { id: 1, label: "Query Text" },
          { id: 111, label: "Query Hash" },
          {
            id: 2,
            label: "Est. Cost",
            filter: {
              filterType: "text",
              value: queryCostFilter,
              placeHolder: `Specify cost in ${currencySymbol}`,
              onChange: handleCostFilterChange,
              label: "Estimate Cost",
              searchParamKey: "queryCost",
              filterMode: { value: executionCostFilterMode },
            },
            sort: {
              onChange: handleCostSortChange,
              value: sortOrder.query_cost,
            },
          },
          {
            id: 3,
            label: "Exec. Time",
            filter: {
              filterType: "text",
              value: executionTimeFilter,
              placeHolder: "Specify time in mins",
              onChange: handleExecutionTimeFilterChange,
              label: "Execution Time",
              searchParamKey: "executionTime",
              filterMode: { value: executionTimeFilterMode },
              allowFractional: true,
            },
            sort: {
              onChange: handleExecutionTimeSortChange,
              value: sortOrder.execution_time,
            },
          },
          {
            id: 4,
            label: "Insights",
            filter: {
              filterType: "dropdown",
              searchParamKey: "tags",
              value: tagFilter,
              searchBy: "value",
              options: filters?.tags
                ? filters.tags.map(
                    (t: {
                      name: string;
                      cost_savings: boolean;
                      time_savings: boolean;
                    }) => ({
                      label: <InsightLabel tag={t} />,
                      value: t.name,
                    })
                  )
                : [],
              onChange: handleInsightsFilterChange,
            },
          },
          {
            id: 5,
            label: "Timestamp",
            sort: {
              onChange: handleTimestampSortChange,
              value: sortOrder.end_time,
            },
          },
          { id: 6, label: "Query Type" },
          {
            id: 8,
            label: "User",
            filter: {
              filterType: "dropdown",
              searchParamKey: "users",
              value: userFilter,
              options: filters?.users
                ? filters.users.map((w: string) => ({
                    label: w,
                    value: w,
                  }))
                : [],
              onChange: handleUserFilterChange,
            },
          },
        ]}
        onRowClick={(v: string) =>
          navigate(
            `/query/${v}${startDate ? "?start_date=" + startDate : ""}${
              endDate ? "&end_date=" + endDate : ""
            }`
          )
        }
        queries={queries}
        resetPage={setPage}
        showWarehouse={false}
      />
      <Paginate
        itemCount={queries.total}
        page={page}
        pageSize={queries.size}
        numPages={queries.pages}
        onPageClick={setPage}
        onPageSizeChange={() => {}}
      />
    </div>
  );
};
