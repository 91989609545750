import { User } from "@/types";
import dayjs from "dayjs";
import _ from "lodash";
import { formatNumber as fN } from "@/uiCore";

export const getTrueKeys = (x: unknown[]) => {
  const result: unknown[] = [];
  x.forEach((v, k) => {
    if (!v) return;
    if (Array.isArray(k)) result.push(...k);
    else result.push(k);
  });
  return result;
};

export const isEmpty = (arr?: unknown[]) =>
  !arr || !Array.isArray(arr) || arr.length === 0;

// add proper types for these functions
export const toggleSetValue = (s: Set<string>, key: string) =>
  s.delete(key) ? s : s.add(key);

export const getScopeString = (scope: string[]) => scope.join("/");

export const isStrDateOlderThan = (dateStr: string, numDays: number) =>
  dayjs().diff(dayjs(dateStr, "DD-MMM-YY HH:mm"), "day") > numDays;

export const containsScope = (scopeArr: string[][], scope: string[]) =>
  scopeArr.length === 0
    ? false
    : !!scopeArr.find((item) => _.isEqual(item, scope));

export const downloadBlob = (blob: Blob) => {
  var url = window.URL.createObjectURL(blob);
  var a = document.createElement("a");
  a.href = url;
  document.body.appendChild(a); // append the element to the dom
  a.click();
  a.remove(); // afterwards, remove the element
};

export function downloadFileFromUrl(url: string, filename: string) {
  const link = document.createElement("a");
  link.href = url;
  link.target = "_blank";
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const onSortChange =
  (key: string, setSort: Function, setSortAttribute: Function) =>
  (newOrder: string) => {
    setSort((prevSortOrder: Record<string, string>) => ({
      ...prevSortOrder,
      [key]: newOrder,
    }));
    setSortAttribute(key);
  };

export const roundToTwoDecimalPlaces = (number: number) => {
  return Math.round(number * 100) / 100;
};

export const getUserById = (users: User[], id: User["id"]) =>
  users?.find((u) => u.id === id);

export function formatNumber(n: string, wholeNumber = false) {
  const number = parseFloat(n);

  const divisors = {
    "1000": "",
    "1000000": "K",
    "1000000000": "M",
    "1000000000000": "B",
  };
  let divisor = 1000000000000;
  let suffix = "B";

  for (let l in divisors) {
    const limit = Number(l);
    if (number < Number(limit)) {
      divisor = limit / 1000;
      suffix = divisors[l as keyof typeof divisors];
      break;
    }
  }

  let formattedNumber;
  if (1000000 <= number && number < 1000000000) {
    formattedNumber = (number / 1000000).toFixed(1) + "M";
  } else {
    formattedNumber =
      number >= 10 || wholeNumber
        ? (Math.ceil((number / divisor) * 10) / 10 + suffix).replace(".0", "")
        : number.toFixed(2);
  }

  return formattedNumber;
}

export const formatDate = (date: string) => {
  if (typeof date === "string") {
    try {
      const formattedDate = dayjs(date).format("DD MMM, YYYY");
      if (date.includes("<")) {
        return "Before " + formattedDate;
      } else if (date.includes("N/A")) {
        return "N/A";
      }
      return formattedDate;
    } catch {
      return date;
    }
  } else {
    return "None";
  }
};
export const formatCost = (cost: string, currency: string) => {
  try {
    if (isNaN(parseFloat(cost))) {
      return cost;
    }
    return fN(cost, { currency });
  } catch {
    return cost;
  }
};
