export const GET_SUMMARY_DATA = "GET_SUMMARY_DATA";
export const GET_OPPORTUNITIES_DATA = "GET_OPPORTUNITIES_DATA";
export const GET_OPPORTUNITY_NAME_FILTERS = "GET_OPPORTUNITY_NAME_FILTERS";

export const SORT_OPTIONS = [
  { label: "Money Savings(max)", value: "cost_savings_max" },
  { label: "Money Savings(min)", value: "cost_savings_min" },
  { label: "Time Savings(max)", value: "time_savings_max" },
  { label: "Time Savings(min)", value: "time_savings_min" },
];

export const PERIOD_OPTIONS = [
  { label: "Day", value: "day" },
  { label: "Week", value: "week" },
  { label: "Month", value: "month" },
];

export const EFFORT_OPTIONS = [
  { label: "Low", value: "low" },
  { label: "Medium", value: "medium" },
  { label: "High", value: "high" },
];

export const RESOURCE_TYPE_OPTIONS = [
  { label: "Query Hash", value: "query_hash" },
  { label: "Table", value: "table" },
  { label: "Warehouse", value: "warehouse" },
  { label: "User", value: "user" },
  { label: "Role", value: "role" },
];

export const STATUS_OPTIONS = [
  { label: "Active", value: "active" },
  { label: "Implemented", value: "implemented" },
  { label: "Dismissed", value: "dismissed" },
];

export const ResourceTagColors = [
  { color: "#CCE2FF", textColor: "#247EFE" },
  { color: "#FFEAE4", textColor: "#FF7064" },
  { color: "#FFF4C3", textColor: "#893A00" },
];

export const EffortTagColors = {
  low: "#FAC515",
  medium: "#FF754C",
  high: "#EE140F",
  zero: "#079455",
};

export const StatusTagColors = {
  active: { color: "#E0EDFF", textColor: "#247EFE" },
  implemented: { color: "#D3FFEB", textColor: "#079455" },
  dismissed: { color: "#FEEEEE", textColor: "#EE140F" },
};
