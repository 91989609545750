import React, { useCallback, useMemo, MutableRefObject } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import {
  ColDef,
  GridReadyEvent,
  RowClickedEvent,
  SelectionChangedEvent,
} from "ag-grid-community";
import { Paginate } from "../Paginate";

type DataGridProps = {
  headers: Array<ColDef>;
  data: { items: any[]; total: number; pages: number } | null;
  onCellClicked?: (event: any) => void;
  onRowClicked?: (event: RowClickedEvent) => void;
  onSelectionChanged?: (event: SelectionChangedEvent) => void;
  page: number;
  pageSize: number;
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
  gridApiRef: MutableRefObject<any>;
  loading?: boolean;
};

const DataGrid: React.FC<DataGridProps> = ({
  headers,
  data,
  onCellClicked,
  onRowClicked,
  onSelectionChanged,
  page,
  pageSize,
  setPage,
  setPageSize,
  gridApiRef,
  loading = false,
}) => {
  const getColumnDefs = useCallback(() => {
    return headers.map((col) => ({
      field: col.field,
      sortable: false,
      filter: false,
      autoHeight: true,
      ...col,
    }));
  }, [headers]);

  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      params.api.sizeColumnsToFit();
      gridApiRef.current = params.api;
    },
    [gridApiRef]
  );

  const rowData = useMemo(() => data?.items || [], [data]);

  return (
    <div className="ag-theme-alpine" style={{ width: "100%" }}>
      <AgGridReact
        rowData={rowData}
        columnDefs={getColumnDefs()}
        onGridReady={onGridReady}
        onCellClicked={onCellClicked}
        onRowClicked={onRowClicked}
        onSelectionChanged={onSelectionChanged}
        domLayout="autoHeight"
        suppressPaginationPanel
        suppressRowClickSelection
        rowSelection="multiple"
        loading={loading}
      />
      {data && (
        <Paginate
          itemCount={data.total}
          page={page}
          pageSize={pageSize}
          numPages={data.pages}
          onPageClick={setPage}
          showPageSize
          onPageSizeChange={setPageSize}
        />
      )}
    </div>
  );
};

export default DataGrid;
