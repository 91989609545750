import { BadgeCustomIcon, BadgeIcon } from "@assets/icons";
import { EventType } from "./types";

export const getIcon = ({ type }: { type: EventType }) => {
  switch (type) {
    case "custom":
      return <BadgeCustomIcon />;
    default:
      return <BadgeIcon />;
  }
};

export const WarehouseSizeMap: { [key: string]: string } = {
  "X-Small": "XS",
  Small: "S",
  Medium: "M",
  Large: "L",
  "X-Large": "XL",
  "2X-Large": "2XL",
  "3X-Large": "3XL",
  "4X-Large": "4XL",
  "5X-Large": "5XL",
  "6X-Large": "6XL",
};

export function getSizeAbbreviation(sizeStr: string) {
  return WarehouseSizeMap[sizeStr] || undefined;
}

export function transformWarehouseSizeString(input: string) {
  return input.replace(/\s\(\d+\)$/, ""); // Removes " (<num>)"
}

export function parseRecommendationReasoning(reasoning: string[]) {
  const splitReasoning = reasoning[0].split("Predicted pattern:\n- ");
  const parsedReasons =
    splitReasoning.length > 1 ? splitReasoning[1] : splitReasoning[0];
  const finalReasoning = parsedReasons.split("- ");
  return finalReasoning || reasoning;
}