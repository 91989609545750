export const EventTypeInfo = {
  custom: {
    key: "custom",
    label: "Custom",
    textColor: "white",
    backendLabel: "manual",
  },
  recommended: {
    key: "recommended",
    label: "Recommended",
    textColor: "white",
    backendLabel: "recommendation",
  },
  block: {
    key: "block",
    label: "Blocked",
    textColor: "white",
    backendLabel: "block",
  },
  deleted: {
    key: "deleted",
    label: "Deleted",
    textColor: "black",
    backendLabel: "deleted",
  },
  default: {
    key: "default",
    label: "Default",
    textColor: "black",
    backendLabel: "default",
  },
};

export enum ScheduleStatus {
  Pending = "pending",
  Approved = "approved",
  Rejected = "rejected",
}

export const WarehouseScheduleOpportunity = "warehouse_schedule_recommendation";

export const WarehouseScheduleRecurrenceEndDate = "2100-01-01T00:00:00.000Z";

export const GET_SCHEDULED_OPPORTUNITY_DATA = "GET_SCHEDULED_OPPORTUNITY_DATA";
