import styles from "./styles.module.scss";
import { Filter } from "./Filter";
import { TableList } from "./TableList";
import { useContext, useEffect, useMemo, useState } from "react";
import { ColumnsModal } from "./ColumnsModal";
import { useNavigate } from "react-router-dom";
import {
  ComponentLoader,
  RelativeComponentLoader,
} from "../../Components/Loader";
import SidebarModal from "../../Components/SidebarModal";
import { FilterTagWrapper } from "../../Components/Tags";
import classNames from "classnames";
import { useMutation, useQuery } from "react-query";
import { GET_DBT_MODELS, GET_DBT_MODELS_FILTERS } from "./constants";
import {
  getDBTModelByRk,
  getDBTModelFilters,
  getDBTModels,
} from "../../helpers/backend_helper";
import { SearchContext } from "../Home";
import { Card, CardBody } from "reactstrap";
import { NoDbtModels } from "./NoDbtModels";
import useFiltersBySearchParams from "@/helpers/useFiltersBySearchParams";

const FILTER_SEARCH_ENABLED = {
  dbt_integrations: true,
  environments: true,
  projects: true,
  resource_types: false,
  materializations: false,
};
const FILTER_LABELS = {
  dbt_integrations: "dbt Core Integration",
  environments: "Environments",
  projects: "Projects",
  resource_types: "Resource Type",
  materializations: "Materialization",
};
const FILTER_BE_KEYS = {
  dbt_integrations: "dbt_core_integration_ids",
  environments: "dbt_core_integration_environment_ids",
  projects: "project_rk",
  resource_types: "resource_type",
  materializations: "materialization",
};

const FILTER_KEYS = [
  "dbt_integrations",
  "environments",
  "projects",
  "resource_types",
  "materializations",
];

const getDefaultFilter = () => {
  const defaultFilter = {};
  FILTER_KEYS.forEach((k) => (defaultFilter[k] = []));
  return defaultFilter;
};

const NoSearchResults = ({ search, itemCount }) => (
  <div className={styles.table_list}>
    <Card>
      <CardBody>
        <div className="d-flex justify-content-between align-items-center">
          <div className="fs-5 text-muted">{itemCount} results</div>
        </div>
        <div className={styles.card}>
          <div className="fs-4 fw-semibold mb-2">
            No results found for :{" "}
            <span className="text-primary">{search}</span>
          </div>
          <div className="fs-5 mb-2">Search help</div>
          <ul className="text-muted mb-2">
            <li>Check your search for typos</li>
            <li>Use more specific search terms</li>
          </ul>
        </div>
      </CardBody>
    </Card>
  </div>
);

const DbtModels = () => {
  const { search } = useContext(SearchContext);
  const {searchParams, deleteSearchParamsByKeyValue, updateSearchParamsByKey} = useFiltersBySearchParams();
  const navigate = useNavigate();

  const [schemaTable, setSchemaTable] = useState(null);
  const [modelError, setModelError] = useState(null);
  const [errorForRk, setErrorForRk] = useState("");
  const [filterApplied, setFilterApplied] = useState(false);

  const filter = useMemo(() => {
    if (!searchParams.size) return getDefaultFilter();
    return FILTER_KEYS.reduce((acc, key) => {
      const params = searchParams.getAll(key);
      if (params.length) {
        acc[key] = params;
      }
      return acc;
    }, {});
  }, [searchParams]);
  
  const { data: dbtModelsFilters, isLoading: isFilterLoading } = useQuery({
    queryKey: [GET_DBT_MODELS_FILTERS],
    queryFn: async () => {
      const dbtModelsFilters = await getDBTModelFilters();
      const envGroups = {};
      dbtModelsFilters.environments.forEach((e) => {
        envGroups[e.label] = envGroups[e.label] || [];
        envGroups[e.label].push(e.value);
      });
      dbtModelsFilters.environments = Object.entries(envGroups).map(
        ([k, v]) => ({
          label: k,
          value: k,
          _value: v,
        })
      );
      return dbtModelsFilters;
    },
  });

  const filterTagsProps = useMemo(() => {
    if (!dbtModelsFilters) return [];
    return Object.entries(filter).map(([k, v]) => ({
      label: FILTER_LABELS[k],
      isSearchOption: FILTER_SEARCH_ENABLED[k],
      filterStr: dbtModelsFilters[k]
        .filter((i) => v.includes(i.value))
        .map((i) => i.label),
      onclose: (label) => {
        const v = dbtModelsFilters[k].find((i) => i.label === label)?.value;
        if (!v) return;
        deleteSearchParamsByKeyValue({[k]: v});
      },
    }));
  }, [dbtModelsFilters, filter]);

  const filterOptions = useMemo(() => {
    if (!dbtModelsFilters) return [];
    return FILTER_KEYS.map((key) => {
      return {
        label: FILTER_LABELS[key],
        isSearchOption: FILTER_SEARCH_ENABLED[key],
        options: dbtModelsFilters[key]
          .filter((item) => Boolean(item.label)) // Ensure label is not null or undefined
          .map((item) => ({
            ...item,
            checked: (filter[key] || []).includes(item.value),
          })),
        onChange: (v) => {
          if (filter[key]?.includes(v)) {
            deleteSearchParamsByKeyValue({[key]: v});
          } else {
            updateSearchParamsByKey({[key]: [...(filter[key] || []), v]});
          }
        },
      };
    });
  }, [dbtModelsFilters, filter]);

  const [page, setPage] = useState(0);

  useEffect(() => {
    setPage(0);
  }, [search]);

  const getTableParams = () => {
    const params = { page: page + 1, size: 8 };
    for (const k of FILTER_KEYS) {
      if (!filter[k]?.length) {
        continue;
      }
      if (k === "environments") {
        params[FILTER_BE_KEYS[k]] = dbtModelsFilters[k]
          .filter((item) => filter[k].includes(item.value))
          .flatMap((item) => item._value);
      } else {
        params[FILTER_BE_KEYS[k]] = filter[k];
      }
    }
    if (search) params.search = search;
    return params;
  };

  const { data, isLoading } = useQuery({
    queryKey: [
      GET_DBT_MODELS,
      page,
      search,
      ...FILTER_KEYS.flatMap((k) => filter[k]),
    ],
    queryFn: () => getDBTModels(getTableParams()),
    onSuccess: (data) => {
      if (data.pages <= page) setPage(data.pages > 1 ? data.pages - 1 : 1);
      if (data?.total) setFilterApplied(true);
    },
  });

  const { mutate: fetchdbtModel } = useMutation(getDBTModelByRk, {
    onSuccess: (data) => {
      navigate(`/dbt_models/${btoa(data.rk)}`, {
        state: { from: "dbt_models" },
      });
      setErrorForRk("");
    },
    onError: (error) => {
      setModelError(error);
      setTimeout(() => {
        setModelError("");
        setErrorForRk("");
      }, 5000);
    },
  });

  const handleDetailsClick = (table) => {
    if (table?.rk) {
      fetchdbtModel({ dbt_model_rk: table?.rk });
      setErrorForRk(table?.rk);
    }
  };

  if (!isLoading && !filterApplied && (!data || !data?.total)) {
    return <NoDbtModels />;
  }

  return (
    <>
      <div>
        <div className="mb-4 d-flex gap-lg align-items-center">
          <h3 className="text-black">dbt Models</h3>
          <div
            className={classNames(
              styles.filter_tags,
              "d-flex gap-sm align-items-center"
            )}
          >
            <FilterTagWrapper filters={filterTagsProps} />
          </div>
        </div>
        <div className={styles.dataset_container}>
          {isFilterLoading ? (
            <RelativeComponentLoader />
          ) : (
            <Filter
              resetFilter={() => {
                deleteSearchParamsByKeyValue(FILTER_KEYS.reduce((acc, key) => ({...acc, [key]: undefined}), {}));
              }}
              filterOptions={filterOptions}
            />
          )}
          {isLoading ? (
            <ComponentLoader left={70} label="Loading dbt models..." />
          ) : search && data.total === 0 ? (
            <NoSearchResults search={search} itemCount={data.total} />
          ) : (
            <TableList
              tables={data.items}
              onItemClick={handleDetailsClick}
              paginate={{
                itemCount: data.total,
                page: page,
                pageSize: data.size,
                numPages: data.pages,
                onPageClick: setPage,
              }}
              modelError={modelError}
              errorForRk={errorForRk}
              onDetailsClick={handleDetailsClick}
              onViewSchema={setSchemaTable}
            />
          )}
        </div>
      </div>
      <SidebarModal
        isOpen={!!schemaTable}
        toggleModal={() => setSchemaTable(null)}
        width="446"
      >
        <ColumnsModal table={schemaTable} onDetailsClick={handleDetailsClick} />
      </SidebarModal>
    </>
  );
};

export { DbtModels };
