import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, { Suspense, useEffect, useState } from "react";
import "./assets/scss/themes.scss";
import "./App.css";
import { QueryCache, QueryClient, QueryClientProvider } from "react-query";
import { SomethingWrong } from "./pages/SomethingWrong";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigate,
  useNavigationType,
} from "react-router-dom";
import {
  getDefaultRoute,
  getTenant,
  isFreeStack,
  isInstanceStack,
  isSSOEnabled,
} from "./helpers/tenant";
import {
  setAuthorizationHeader,
  setTenantHeader,
  setTimezoneHeader,
} from "./helpers/api_helper";
import {
  Expired,
  Forgot,
  FreeRegister,
  Login,
  Register,
  Reset,
  SuperTokenLogin,
  Verified,
} from "./pages/Authentication";
import { ApiKey, CompanyList, UserList, Notification } from "./pages/settings";
import { ComingSoon } from "./pages/ComingSoon";
import Quickstart from "./pages/Quickstart";
import Profile from "./pages/Profile/Profile";
import { Datasets, DatasetsDetails } from "./pages/Datasets";
import { DbtModels, DbtModelsDetails } from "./pages/DbtModels";
import { Incidents } from "./pages/Incidents";
import { getUser, logoutUser } from "./services/auth";
import { Home } from "./pages/Home";
import { ContractDetails, ContractTabs } from "./pages/Contracts";
import { Copilot, CopilotProvider } from "./pages/Copilot";
import { Datastores, DatastoreDetails, DatastoreAdd } from "./pages/Datastores";
import * as Sentry from "@sentry/react";
import { ENV, SENTRY_DSN, INTERCOM_APP_ID } from "./config";
import "react-tooltip/dist/react-tooltip.css";
import { QueryContainer } from "./pages/Query";
import { Security } from "./pages/Security/Security";
import { Credits } from "./pages/Credits";
import { WarehouseDetails, Warehouses } from "./pages/Warehouse";
import { Summary, SummaryV2 } from "./pages/Summary";
import { Governance } from "./pages/Governance";
import { ContactUs } from "./pages/ContactUs/ContactUs";
import DatasetsList from "./pages/Datasets/DatasetsList";
import { AlertNotifications, AlertRules } from "./pages/Alerts";
import { AddIntegration, IntegrationList } from "./pages/Integrations";
import DbtDocsSharesList from "./pages/DbtDocs/DbtDocsSharesList";
import DbtDocsDetails from "./pages/DbtDocs/DbtDocsDetails";
import {
  Experimentation,
  ExperimentationAdd,
  ExperimentationItem,
} from "./pages/Experimentation";
import { AllQueries } from "./pages/Query/Query";
import { Groups } from "./pages/Query/Groups";
import { AutoTuneJobs, Jobs } from "./pages/Query/Jobs";
import { RelativeComponentLoader } from "./Components/Loader";
import CookieConsentBanner from "./Components/CookieConsentBanner/CookieConsentBanner";
import { FindInstance } from "./pages/Authentication/FindInstance";
import { QueryDetails } from "./pages/Query/QueryDetails";
import { GroupDetails } from "./pages/Query/GroupDetails";
import { Explorer } from "./pages/Explorer";
import { default as Teammates } from "./pages/Teammate/Teammates";
import { default as Teammate } from "./pages/Teammate/Teammate";
import AppProvider from "./modules/app/AppProvider";
import "./dayjsConfig";
import { TeamMateProvider } from "@components";
import { InsightProvider } from "./pages/Query/components";
import Session from "supertokens-auth-react/recipe/session";
import { SSORegister } from "./pages/Authentication/SSORegister";
import { ExportLineage, ExportLineageList } from "./pages/ExportLineage";
import { initializeSuperTokens, SupertokensProvider } from "./supertokens";
import { useAppDispatch } from "./modules/app/useAppContext";
import { setAuthHeaderSet } from "./modules/app/appSlice";
import LocationTracker from "./modules/app/LocationTracker";

const QueryResult = React.lazy(() => import("./pages/QueryResult/QueryResult"));
const QueryResultItem = React.lazy(() =>
  import("./pages/QueryResult/QueryResultItem")
);
const DBTConfigAdd = React.lazy(() => import("./pages/DBTConfig/DBTConfigAdd"));
const DBTConfigList = React.lazy(() =>
  import("./pages/DBTConfig/DBTConfigList")
);
const ssoMode = isSSOEnabled();
initializeSuperTokens();

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    // Captures all api errors, will noisy initially ex: login but we could filter out later
    onError: (error) => {
      Sentry.captureException(error);
    },
  }),
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      cacheTime: 0,
      refetchOnWindowFocus: false,
    },
  },
});

if (ENV === "staging" || ENV === "production") {
  Sentry.init({
    environment: ENV,
    dsn: SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new Sentry.Replay(),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const SSOAuthProtected = () => {
  const location = useLocation();
  const user = getUser();
  const tenant = getTenant();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const appDispatch = useAppDispatch();

  useEffect(() => {
    const checkSession = async () => {
      const sessionExists = await Session.doesSessionExist();
      if (!sessionExists) {
        console.debug("Session does not exist");
        logoutUser();
        navigate("/login", {
          state: { from: location.pathname, expired: true },
        });
      } else if (!user) {
        console.debug("Session exists but no user found");
        navigate("/register");
      } else {
        console.debug("Session exists and user found");
        appDispatch(setAuthHeaderSet(true));
        setAuthorizationHeader(user.auth_token);
        setLoading(false);
      }
    };

    checkSession();
  }, []);

  if (loading) {
    return null;
  }

  return <Outlet />;
};

const BaseAuthProtected = () => {
  const location = useLocation();
  const user = getUser();
  const tenant = getTenant();
  const appDispatch = useAppDispatch();
  
  useEffect(() => {
    if (!user) {
      return;
    }
    appDispatch(setAuthHeaderSet(true));

  }, [appDispatch, user]);

  if (!user) {
    logoutUser();
    return (
      <Navigate
        to="/login"
        state={{ from: location.pathname, expired: true }}
      />
    );
  }
  setAuthorizationHeader(user.auth_token);
  setTimezoneHeader();
  return <Outlet />;
};

const AuthProtected = () => {
  if (ssoMode) {
    return <SSOAuthProtected />;
  }
  return <BaseAuthProtected />;
};

const TenantProtected = (props) => {
  if (props.tenant === "") {
    return <Navigate to="/coming-soon" />;
  }

  return <Outlet />;
};

function App() {
  const isFreeUser = isFreeStack();
  const hasInstanceName = isInstanceStack();
  const tenant = getTenant();
  const user = getUser();
  setTenantHeader(tenant);
  
  return (
    <div className="App">
      <SupertokensProvider>
        <AppProvider>
          <Sentry.ErrorBoundary fallback={<SomethingWrong />}>
            <QueryClientProvider client={queryClient}>
              <TeamMateProvider>
                <CopilotProvider>
                  <InsightProvider>
                    <BrowserRouter>
                      <LocationTracker />
                      <SentryRoutes>
                        {ssoMode ? (
                          // * is needed because of supertoken callback url
                          <>
                            <Route
                              path="/login/*"
                              element={<SuperTokenLogin />}
                            />
                            <Route path="/register" element={<SSORegister />} />
                            <Route
                              path="/register/:token"
                              element={<Navigate to="/login" />}
                            />
                          </>
                        ) : (
                          <>
                            {isFreeUser ? (
                              <Route
                                path="/contactus"
                                element={<ContactUs />}
                              />
                            ) : null}
                            {isFreeUser && !hasInstanceName ? (
                              <Route path="/login" element={<FindInstance />} />
                            ) : (
                              <Route path="/login" element={<Login />} />
                            )}
                            <Route path="/forgot" element={<Forgot />} />
                            <Route
                              path="/register/:token"
                              element={<Register />}
                            />
                            <Route path="/reset/:token" element={<Reset />} />
                            {isFreeUser && (
                              <>
                                <Route
                                  path="/register"
                                  element={<FreeRegister />}
                                />
                                <Route
                                  path="/login/:token"
                                  element={<Login />}
                                />
                                <Route
                                  path="/verified"
                                  element={<Verified />}
                                />
                              </>
                            )}
                          </>
                        )}
                        <Route path="/expired" element={<Expired />} />
                        <Route element={<AuthProtected />}>
                          <Route element={<Home />}>
                            <Route
                              path="/coming-soon"
                              element={<ComingSoon />}
                            />
                            {isFreeUser && (
                              <Route
                                path="/quickstart"
                                element={<Quickstart />}
                              />
                            )}
                            {/* <Route path="/profile" element={<Profile />} /> */}
                            <Route
                              element={<TenantProtected tenant={tenant} />}
                            >
                              <Route path="/tables">
                                <Route index element={<DatasetsList />} />
                              </Route>
                              <Route path="/datasets">
                                <Route index element={<Datasets />} />
                                <Route
                                  path=":tableId"
                                  element={<DatasetsDetails />}
                                />
                              </Route>
                              <Route path="/dbt_models">
                                <Route index element={<DbtModels />} />
                                <Route
                                  path=":dbtModelId"
                                  element={<DbtModelsDetails />}
                                />
                              </Route>
                              <Route path="/experimentation">
                                <Route index element={<Experimentation />} />
                                <Route
                                  path="new"
                                  element={<ExperimentationAdd />}
                                />
                                <Route
                                  path=":experimentId"
                                  element={<ExperimentationItem />}
                                />
                              </Route>
                              <Route path="/discover">
                                <Route index element={<Explorer />} />
                              </Route>
                              <Route path="/incidents">
                                <Route index element={<Incidents />} />
                                <Route
                                  path=":incidentId"
                                  element={<Incidents />}
                                />
                                <Route
                                  path="event/:eventId"
                                  element={<Incidents />}
                                />
                              </Route>
                              <Route path="/contracts">
                                <Route index element={<ContractTabs />} />
                                <Route
                                  path=":contractId"
                                  element={<ContractDetails />}
                                />
                              </Route>
                              <Route path="/copilot">
                                <Route index element={<Copilot />} />
                              </Route>
                              <Route path="/checks">
                                <Route index element={<AlertRules />} />
                                <Route
                                  path=":alertId"
                                  element={<AlertNotifications />}
                                />
                                <Route
                                  path="events"
                                  element={<AlertNotifications />}
                                />
                              </Route>
                              <Route
                                path="/query/:queryId"
                                element={<QueryDetails />}
                              />
                              <Route
                                path="/query/groups/:queryHash"
                                element={<GroupDetails />}
                              />
                              <Route path="/query" element={<QueryContainer />}>
                                <Route index element={<Navigate to="all" />} />
                                <Route path="all" element={<AllQueries />} />
                                <Route path="groups" element={<Groups />} />
                                <Route path="jobs" element={<Jobs />}>
                                  <Route index element={<Jobs />} />
                                  <Route path=":jobID" element={<Jobs />} />
                                </Route>
                                <Route
                                  path="autotune_jobs"
                                  element={<AutoTuneJobs />}
                                >
                                  <Route index element={<AutoTuneJobs />} />
                                  <Route
                                    path=":jobID"
                                    element={<AutoTuneJobs />}
                                  />
                                </Route>
                              </Route>
                              <Route path="/warehouse">
                                <Route index element={<Warehouses />} />
                                <Route
                                  path=":warehouse_rk"
                                  element={<WarehouseDetails />}
                                />
                              </Route>

                              <Route path="/governance">
                                <Route path="dbtconfig">
                                  <Route
                                    index
                                    element={
                                      <Suspense
                                        fallback={<RelativeComponentLoader />}
                                      >
                                        <DBTConfigList />
                                      </Suspense>
                                    }
                                  />
                                  <Route
                                    path="new"
                                    element={
                                      <Suspense
                                        fallback={<RelativeComponentLoader />}
                                      >
                                        <DBTConfigAdd />
                                      </Suspense>
                                    }
                                  />
                                  <Route
                                    path=":id"
                                    element={
                                      <Suspense
                                        fallback={<RelativeComponentLoader />}
                                      >
                                        <DBTConfigAdd />
                                      </Suspense>
                                    }
                                  />
                                </Route>
                              </Route>
                              <Route path="/summary">
                                <Route index element={<SummaryV2 />} />
                                <Route path="v1" element={<Summary />} />
                              </Route>
                              <Route path="/users">
                                <Route index element={<Governance />} />
                              </Route>
                              <Route path="/roles">
                                <Route
                                  index
                                  element={<Governance defaultTab={1} />}
                                />
                              </Route>
                              <Route path="/collaboration/query-result">
                                <Route
                                  index
                                  element={
                                    <Suspense
                                      fallback={<RelativeComponentLoader />}
                                    >
                                      <QueryResult />
                                    </Suspense>
                                  }
                                />
                                <Route
                                  path=":id"
                                  element={
                                    <Suspense
                                      fallback={<RelativeComponentLoader />}
                                    >
                                      <QueryResultItem />
                                    </Suspense>
                                  }
                                />
                              </Route>
                            </Route>
                            {isFreeUser && (
                              <Route path="/collaboration/dbt-docs">
                                <Route index element={<DbtDocsSharesList />} />
                                <Route
                                  path=":shareId/:conversationGroupId?"
                                  element={<DbtDocsDetails />}
                                />
                              </Route>
                            )}
                            <Route path="/collaboration/export-lineage">
                              <Route index element={<ExportLineageList />} />
                              <Route
                                path=":itemId"
                                element={<ExportLineage />}
                              />
                            </Route>
                            <Route path="/settings">
                              <Route path="api-key" element={<ApiKey />} />
                              <Route
                                path="datastores"
                                element={<Datastores />}
                              />
                              <Route
                                path="datastores/add"
                                element={<DatastoreAdd />}
                              />
                              <Route
                                path="datastores/:datastoreId"
                                element={<DatastoreDetails />}
                              />
                              <Route path="users" element={<UserList />} />
                              <Route
                                path="companies"
                                element={<CompanyList />}
                              />
                              <Route
                                path="companies/:company"
                                element={<UserList />}
                              />
                              <Route path="integrations">
                                <Route index element={<IntegrationList />} />
                                <Route
                                  path="new"
                                  element={<AddIntegration />}
                                />
                                <Route
                                  path=":dbtCoreIntegrationId/:dbtCoreIntegrationEnv"
                                  element={<IntegrationList />}
                                />
                              </Route>
                              <Route
                                path="notification"
                                element={<Notification />}
                              />
                              {!isFreeUser && (
                                <Route path="security" element={<Security />} />
                              )}
                              <Route path="credits" element={<Credits />} />
                            </Route>
                            <Route path="teammates" element={<Teammates />} />
                            <Route
                              path="teammates/:teammate"
                              element={<Teammate />}
                            />
                            {isFreeUser ? (
                              <Route path="/" element={<Quickstart />}>
                                {/* TODO: Remove in future */}
                                <Route
                                  path="/settings/executions"
                                  element={<Navigate to="/settings/credits" />}
                                />
                                <Route
                                  path="*"
                                  element={<Navigate to="/copilot" />}
                                />
                              </Route>
                            ) : (
                              <Route
                                path="*"
                                element={
                                  <Navigate to={getDefaultRoute(tenant)} />
                                }
                              />
                            )}
                          </Route>
                        </Route>
                      </SentryRoutes>
                    </BrowserRouter>
                  </InsightProvider>
                  <ToastContainer />
                </CopilotProvider>
              </TeamMateProvider>
            </QueryClientProvider>
          </Sentry.ErrorBoundary>
          <CookieConsentBanner />
        </AppProvider>
      </SupertokensProvider>
    </div>
  );
}

export default App;