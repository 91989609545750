import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppStateProps } from "./types";
import { USD, USDSymbol } from "./constants";
import { getCurrencySymbol } from "@/uiCore";
import { GetTenantInfoResponse } from "@/helpers/types";

export const initialState = {
  currency: USD,
  currencySymbol: USDSymbol,
  teammatesEnabled: false,
  loggedinUser: null,
  hasAcceptedCookies: false,
  sidebarExpanded: true,
  authHeaderSet: false,
  previousPath: "",
  rbacEnabled: false,
  userGroup: undefined,
  userGroupsLoaded: false,
  warehouseSchedulerEnabled: false,
} as AppStateProps;

const appSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    setHasAcceptedCookies: (
      state,
      action: PayloadAction<AppStateProps["hasAcceptedCookies"]>
    ) => {
      state.hasAcceptedCookies = action.payload;
    },
    setPreviousPath: (
      state,
      action: PayloadAction<AppStateProps["previousPath"]>
    ) => {
      state.previousPath = action.payload;
    },
    setAuthHeaderSet: (
      state,
      action: PayloadAction<AppStateProps["authHeaderSet"]>
    ) => {
      state.authHeaderSet = action.payload;
    },
    setLoggedinUser: (
      state,
      action: PayloadAction<AppStateProps["loggedinUser"]>
    ) => {
      state.loggedinUser = action.payload;
    },
    setCurrency: (state, action: PayloadAction<AppStateProps["currency"]>) => {
      state.currency = action.payload;
      state.currencySymbol = getCurrencySymbol(action.payload);
    },
    setSidebarExpanded: (
      state,
      action: PayloadAction<AppStateProps["sidebarExpanded"]>
    ) => {
      state.sidebarExpanded = action.payload;
    },
    setTeammatesEnabled: (
      state,
      action: PayloadAction<AppStateProps["teammatesEnabled"]>
    ) => {
      state.teammatesEnabled = action.payload;
    },
    setCurrencySymbol: (
      state,
      action: PayloadAction<AppStateProps["currencySymbol"]>
    ) => {
      state.currencySymbol = action.payload;
    },
    setUserGroup: (
      state,
      action: PayloadAction<AppStateProps["userGroup"]>
    ) => {
      state.userGroup = action.payload;
    },
    updateTenantInfo: (
      state,
      action: PayloadAction<Omit<GetTenantInfoResponse, "frontendUrl">>
    ) => {
      state.teammatesEnabled = action.payload.teammatesEnabled;
      state.currency = action.payload.currency || USD;
      state.rbacEnabled = action.payload.rbacEnabled || false;
      state.warehouseSchedulerEnabled =
        action.payload.warehouseSchedulerEnabled;
    },
    setUserGroupsLoaded: (
      state,
      action: PayloadAction<AppStateProps["userGroupsLoaded"]>
    ) => {
      state.userGroupsLoaded = action.payload;
    },
    setWarehouseSchedulerEnabled: (
      state,
      action: PayloadAction<AppStateProps["warehouseSchedulerEnabled"]>
    ) => {
      state.warehouseSchedulerEnabled = action.payload;
    },
  },
});

export const {
  setCurrency,
  setCurrencySymbol,
  setTeammatesEnabled,
  setLoggedinUser,
  setHasAcceptedCookies,
  setSidebarExpanded,
  setAuthHeaderSet,
  setPreviousPath,
  updateTenantInfo,
  setUserGroup,
  setUserGroupsLoaded,
} = appSlice.actions;
export default appSlice;
