import { Alert, Button, Input } from "reactstrap";
import { QueryHeader } from "./Query";
import { default as ArrowLeftIcon } from "../../assets/icons/arrow_left.svg?react";
import { useEffect, useState } from "react";
import { Paginate } from "../../Components/Paginate";
import styles from "./styles.module.scss";
import { TableHeader } from "./QueryList";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getAllQueryGroupCSVFile,
  getAllQueryGroups,
  getQueryFilters,
  updateQueryGroupName,
} from "../../helpers/backend_helper";
import { ComponentLoader } from "../../Components/Loader";
import {
  GET_ALL_QUERY_GROUPS,
  GET_QUERY_FILTERS,
  GET_QUERY_GROUP_BY_ID,
  QueryGroupTabs,
} from "./constants";
import {
  ColorInsightTags,
  ColorTag,
  FilterTagWrapper,
  Tags,
} from "../../Components/Tags";
import SidebarModal from "../../Components/SidebarModal";
import { default as EditIcon } from "@/assets/icons/edit.svg?react";
import classNames from "classnames";
import { Card, CardBody } from "reactstrap";
import { default as CalendarIcon } from "../../assets/icons/datetime.svg?react";
import { useCopilot } from "../Copilot";
import { useCopilotActions } from "../Copilot/useCopilotActions";
import {
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { DateRange, StaticDateRangeKeys } from "../../Components/DateRange";
import dayjs from "dayjs";
import QueryTextWithHover from "./QueryText";
import InsightLabel from "./InsightLabel";
import { formatNumber, Select } from "@/uiCore";
import { PERIOD_OPTIONS } from "../Explorer/constants";
import {
  getDateByEndOfDayWithoutTimeoffset,
  getDateByStartOfDayWithoutTimeoffset,
} from "@/Components/DateRange/utils";
import { useDateFromSearchParams } from "@/helpers/useTimeHooks";
import { useAppState } from "@/modules/app/useAppContext";
import useFiltersBySearchParams from "@/helpers/useFiltersBySearchParams";

const Groups = () => {
  const location = useLocation();
  const { currency } = useAppState();
  const { setConfig } = useCopilot();
  const { queryHash } = useParams();
  const {
    searchParams,
    deleteSearchParamsByKeyValue,
    updateSearchParamsByKey,
  } = useFiltersBySearchParams();
  const [page, setPage] = useState(0);
  const [sortAttribute, setSortAttribute] = useState("annualized_cost");
  const [sort, setSort] = useState({
    count: "",
    avg_execution_time: "",
    avg_cost: "",
    total_cost: "",
    annualized_cost: "",
  });
  const tagFilter = searchParams.getAll("tags") || [];
  const userFilter = searchParams.getAll("users") || [];
  const warehouseFilter = searchParams.getAll("warehouses") || [];
  const [isDownloading, setIsDownloading] = useState(false);
  const { data: filters, isLoading: isfiltersLoading } = useQuery({
    queryKey: [GET_QUERY_FILTERS, "all"],
    queryFn: () => getQueryFilters({ filter_type: "query_group" }),
  });
  const navigate = useNavigate();

  const getFilteredParams = () => {
    const params = {
      page: page + 1,
      size: 8,
      sort_key: sortAttribute,
      sort_order: sort[sortAttribute] || "desc",
      start_date: getDateByStartOfDayWithoutTimeoffset(startDate).toISOString(),
      end_date: getDateByEndOfDayWithoutTimeoffset(endDate).toISOString(),
    };
    return params;
  };

  const onGroupClick = (group) => {
    setConfig({ entity: group.query_hash, entity_type: "query_group" });
    navigate(
      `/query/groups/${group.query_hash}${
        "?start_date=" + startDate?.toISOString()
      }${"&end_date=" + endDate?.toISOString()}`
    );
  };

  const { resetWorkflow } = useCopilotActions();

  useEffect(() => {
    () => {
      resetWorkflow();
    };
  }, []);

  const { startDate, endDate } = useDateFromSearchParams(
    dayjs().subtract(28, "day").toDate(),
    dayjs().toDate()
  );

  const {
    data: groups,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [
      GET_ALL_QUERY_GROUPS,
      sortAttribute,
      sort[sortAttribute],
      page,
      ...tagFilter,
      ...userFilter,
      ...warehouseFilter,
      startDate,
      endDate,
    ],
    queryFn: () => {
      const params = getFilteredParams();
      if (tagFilter.length > 0) params.tags = tagFilter;
      if (userFilter.length > 0) params.users = userFilter;
      if (warehouseFilter.length > 0) params.warehouses = warehouseFilter;
      if (queryHash) params.query_hash = queryHash;
      params.start_date =
        getDateByEndOfDayWithoutTimeoffset(startDate).toISOString();
      params.end_date =
        getDateByEndOfDayWithoutTimeoffset(endDate).toISOString();
      return getAllQueryGroups(params);
    },
    onSuccess: (res) => {
      if (queryHash) {
        const group = res["items"].find(
          (item) => item.query_hash === queryHash
        );
        if (group) {
          onGroupClick(group);
        }
      }
    },
  });

  const onSortChange = (key) => (newOrder) => {
    setSort((prevSortOrder) => ({
      ...prevSortOrder,
      [key]: newOrder,
    }));
    setSortAttribute(key);
  };

  const { mutateAsync: downloadCSV } = useMutation(
    () => {
      const params = getFilteredParams();
      params.responseType = "blob";
      return getAllQueryGroupCSVFile(params, setIsDownloading);
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
    }
  );

  const handleDownloadClick = async () => {
    setIsDownloading(true);
    try {
      await downloadCSV();
      setIsDownloading(false);
    } catch (error) {
      setIsDownloading(false);
    }
  };

  const handleWarehouseFilterChange = (value) => {
    updateSearchParamsByKey({"warehouses": value});
  };

  const handleUserFilterChange = (value) => {
    updateSearchParamsByKey({"users": value});
  };

  const handleInsightsFilterChange = (value) => {
    updateSearchParamsByKey({"tags": value});
  };

  if (isLoading || isfiltersLoading) return <ComponentLoader />;
  if (!groups) {
    return <Alert color="danger">No data for query group found</Alert>;
  }

  return (
    <div className="d-flex flex-column">
      <QueryHeader />
      <div className="divider" />
      <div className="p-3 d-flex flex-column gap-md bg-white border-radius-bottom">
        <div className="d-flex gap-md align-items-center">
          <div className={styles.filter_tags}>
            <FilterTagWrapper
              filters={{
                tagFilter: {
                  label: "Tags",
                  filterStr: tagFilter,
                  onclose: (removedFilter) => {
                    deleteSearchParamsByKeyValue({ tags: removedFilter });
                  },
                },
                userFilter: {
                  label: "Users",
                  filterStr: userFilter,
                  onclose: (removedFilter) => {
                    deleteSearchParamsByKeyValue({ users: removedFilter });
                  },
                },
                warehouseFilter: {
                  label: "Warehouse",
                  filterStr: warehouseFilter,
                  onclose: (removedFilter) => {
                    deleteSearchParamsByKeyValue({ warehouses: removedFilter });
                  },
                },
              }}
            />
          </div>
          <div className="spacer" />
          <DateRange
            startDate={startDate}
            endDate={endDate}
            availableStaticRanges={["Last day", "Last 7 days", "Last 28 days"]}
            disableCalendarSelection
            onDateRangeSelect={(s, e) => {
              updateSearchParamsByKey({
                start_date: s?.toISOString(),
                end_date: e?.toISOString(),
              });
            }}
          />
          {/* {isDownloading ? (
            <div
              className={classNames(
                "text-primary m-2",
                styles.download_csv_text
              )}
            >
              CSV is being generated and will be ready soon!
            </div>
          ) : (
            <Button
              outline
              onClick={(e) => {
                e.stopPropagation();
                handleDownloadClick();
              }}
              disabled={!(groups && groups.total && !isDownloading)}
            >
              Download CSV file
            </Button>
          )} */}
        </div>
        <div className={styles.group_table}>
          <TableHeader
            header={[
              { id: 1, label: "Group" },
              {
                id: 2,
                label: "Total run",
                sort: {
                  onChange: onSortChange("count"),
                  value: sort.count,
                },
              },
              {
                id: 3,
                label: "Avg. execution time",
                sort: {
                  onChange: onSortChange("avg_execution_time"),
                  value: sort.avg_execution_time,
                },
              },
              {
                id: 4,
                label: "Avg. cost",
                // sort: {
                //   onChange: onSortChange("avg_cost"),
                //   value: sort.avg_cost,
                // },
              },
              {
                id: 5,
                label: "Total cost",
                sort: {
                  onChange: onSortChange("total_cost"),
                  value: sort.total_cost,
                },
              },
              {
                id: 6,
                label: "Annualized cost",
                sort: {
                  onChange: onSortChange("annualized_cost"),
                  value: sort.annualized_cost,
                },
              },
              {
                id: 7,
                label: "Warehouses",
                filter: {
                  filterType: "dropdown",
                  value: warehouseFilter,
                  searchParamKey: "warehouses",
                  options: filters.warehouses
                    ? filters.warehouses.map((warehouse) => ({
                        label: warehouse,
                        value: warehouse,
                      }))
                    : [],
                  onChange: handleWarehouseFilterChange,
                },
              },
              {
                id: 8,
                label: "Users",
                filter: {
                  filterType: "dropdown",
                  searchParamKey: "users",
                  value: userFilter,
                  options: filters.users
                    ? filters.users.map((user) => ({
                        label: user,
                        value: user,
                      }))
                    : [],
                  onChange: handleUserFilterChange,
                },
              },
              {
                id: 9,
                label: "Insights",
                filter: {
                  filterType: "dropdown",
                  searchParamKey: "tags",
                  value: tagFilter,
                  searchBy: "value",
                  options: filters.tags
                    ? filters.tags.map((t) => ({
                        label: <InsightLabel tag={t} />,
                        value: t.name,
                      }))
                    : [],
                  onChange: handleInsightsFilterChange,
                },
              },
            ]}
            resetPage={setPage}
          />
          <div className={styles.table_body}>
            {groups["items"].map((item) => (
              <div
                key={item.query_hash}
                className={styles.table_row}
                onClick={(e) => {
                  e.preventDefault();
                  onGroupClick(item);
                }}
              >
                <div
                  className={classNames(
                    styles.trimmed_query,
                    "text-primary cursor-pointer"
                  )}
                >
                  <QueryTextWithHover
                    queryHash={item.query_hash}
                    queryText={item.query_hash}
                  />
                </div>
                <div className={styles.break_word}>{item.total_run}</div>
                <div className={styles.break_word}>{item.avg_exec_time}</div>
                <div className={styles.break_word}>
                  {formatNumber(item.avg_cost, { currency })}
                </div>
                <div className={styles.break_word}>
                  {formatNumber(item.total_cost, { currency })}
                </div>
                <div className={styles.break_word}>
                  {formatNumber(item.annualized_cost, { currency })}
                </div>
                <div className={classNames(styles.break_word, "lines-2")}>
                  {item.warehouses.join(", ")}
                </div>
                <div className={classNames(styles.break_word, "lines-2")}>
                  {item.users.join(", ")}
                </div>
                <ColorInsightTags tags={item.tags} />
              </div>
            ))}
          </div>
        </div>
        <Paginate
          itemCount={groups.total}
          page={page}
          pageSize={groups.size}
          numPages={groups.pages}
          onPageClick={setPage}
        />
      </div>
    </div>
  );
};

export { Groups };
