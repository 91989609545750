import styles from "./styles.module.scss";
import { useEffect, useMemo, useState } from "react";
import { Card, CardBody } from "reactstrap";
import { RelativeComponentLoader } from "../../Components/Loader";
import { ListingTable } from "../../Components/Tables";
import { Paginate } from "../../Components/Paginate";
import { ColorInsightTags, FilterTagWrapper } from "../../Components/Tags";
import TimeInfoDisclaimer from "../../Components/TimeInfoDisclaimer";
import classNames from "classnames";
import { useQuery } from "react-query";
import {
  getDatasetList,
  getDatasetListFilters,
} from "../../helpers/backend_helper";
import { getIconByDatastoreType } from "./DatastoreImage";
import { useLocation, useNavigate } from "react-router-dom";
import { CopyIconButton } from "../../Components/CopyButton";
import { Tooltip } from "react-tooltip";
import { NoDatasets } from "./NoDatasets";
import { useAppState } from "@/modules/app/useAppContext";
import { formatNumber } from "@/uiCore";
import { StaticFilterDropdown } from "@/Components/FilterDropdown";
import { Tooltip as UITooltip } from "@uicore";
import { InfoGray } from "@assets/icons";
import useFiltersBySearchParams from "@/helpers/useFiltersBySearchParams";

const TableHeader = ({
  numTables,
  tableFilters,
  tableNameFilterDispTag,
  schemaFilterDispTag,
  dbFilterDispTag,
}) => {
  const { updateSearchParamsByKey } = useFiltersBySearchParams();
  const { currencySymbol } = useAppState();
  return (
    <div className="d-flex gap-md align-items-center">
      <div className="fs-4 fw-500 me-3">Tables</div>
      <FilterTagWrapper
        filters={{
          tableRKs: {
            searchKey: "tableNameFilter",
            label: "Table Name",
            filterStr: Object.keys(tableNameFilterDispTag),
            onclose: (removedFilter) => {
              updateSearchParamsByKey({
                tableNameFilter: tableFilters.table_rk.filter(
                  (filter) => filter !== tableNameFilterDispTag[removedFilter]
                ),
              });
            },
          },
          schemas: {
            searchKey: "schemaNameFilter",
            label: "Schema",
            filterStr: Object.keys(schemaFilterDispTag),
            onclose: (removedFilter) => {
              updateSearchParamsByKey({
                schemaNameFilter: tableFilters.filter_schema.filter(
                  (filter) => filter !== schemaFilterDispTag[removedFilter]
                ),
              });
            },
          },
          databases: {
            searchKey: "databaseNameFilter",
            label: "Database",
            filterStr: Object.keys(dbFilterDispTag),
            onclose: (removedFilter) => {
              updateSearchParamsByKey({
                databaseNameFilter: tableFilters.filter_db.filter(
                  (filter) => filter !== dbFilterDispTag[removedFilter]
                ),
              });
            },
          },
          tagRKs: {
            label: "Tags",
            searchKey: "tagFilter",
            filterStr: tableFilters.tag_rks,
          },
          totalCost: {
            searchKey: "totalCostFilter",
            label: `${currencySymbol} Total`,
            filterStr: tableFilters.total_cost,
          },
          insertCost: {
            searchKey: "insertionCostFilter",
            label: `${currencySymbol} Insertion`,
            filterStr: tableFilters.insertion_cost,
            
          },
          storageCost: {
            searchKey: "storageCostFilter",
            label: `${currencySymbol} Storage`,
            filterStr: tableFilters.storage_cost,
          },
          clusteringCost: {
            searchKey: "clusteringCostFilter",
            label: `${currencySymbol} Clustering`,
            filterStr: tableFilters.clustering_cost,
          },
        }}
      />
      <div className="spacer" />
      <TimeInfoDisclaimer
        numOfSecondsActive={3}
        textToDisplay={
          <div className={classNames(styles.info_text, "text-muted m-2")}>
            Tables & costs for the past 30 days
          </div>
        }
      />
      <Card className={classNames("me-3 mb-0 no-break", styles.query_count)}>
        <CardBody className="pt-2 pb-2 ps-4 pe-4">
          <div className="d-flex align-items-center justify-content-between">
            <div>Tables: {numTables}</div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

const GET_DATASET_LIST = "GET-DATASET-LIST";
const GET_DATASET_LIST_FILTERS = "GET-DATASET-LIST-FILTERS";

const TablePath = ({ text, maxLength }) => {
  const truncatedText =
    text.length > maxLength ? text.substring(0, maxLength) + "..." : text;

  return <div className="text-muted condense-text me-2">{truncatedText}</div>;
};

const DatasetsList = () => {
  const { updateSearchParamsByKey, searchParams } = useFiltersBySearchParams();
  const { currency, currencySymbol } = useAppState();
  const { state } = useLocation();
  const [sortAttribute, setSortAttribute] = useState("total_cost");
  const [sortOrder, setSortOrder] = useState({
    total_cost: "",
    insertion_cost: "",
    storage_cost: "",
    clustering_cost: "",
  });
  const size = 20;
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const onTableClick = (item) => navigate(`/datasets/${item.resource_id}`);

  const tagFilter = searchParams.getAll("tagFilter") || [];
  const tableNameFilter = searchParams.getAll("tableNameFilter") || [];
  const schemaFilter = searchParams.getAll("schemaNameFilter") || [];
  const dbFilter = searchParams.getAll("databaseNameFilter") || [];
  const totalCostFilter = searchParams.get("totalCostFilter") || null;
  const insertionCostFilter = searchParams.get("insertionCostFilter") || null;
  const storageCostFilter =  searchParams.get("storageCostFilter") || null;
  const clusteringCostFilter = searchParams.get("clusteringCostFilter") || null;
  
  const getRequestBody = () => {
    const data = { page: page + 1, size: size };
    if (tableNameFilter.length) data.table_rk = tableNameFilter;
    if (schemaFilter.length) data.filter_schema = schemaFilter;
    if (dbFilter.length) data.filter_db = dbFilter;
    if (sortAttribute) {
      data.order_by = sortAttribute;
      data.sort_order = sortOrder[sortAttribute];
    }
    if (totalCostFilter != undefined) data.total_cost = totalCostFilter;
    if (insertionCostFilter != undefined)
      data.insertion_cost = insertionCostFilter;
    if (storageCostFilter != undefined) data.storage_cost = storageCostFilter;
    if (clusteringCostFilter != undefined)
      data.clustering_cost = clusteringCostFilter;
    if (tagFilter.length > 0) data.tag_rks = tagFilter;
    return data;
  };

  const { data: tables, isLoading: isTablesLoading } = useQuery({
    queryKey: [
      GET_DATASET_LIST,
      page,
      ...tableNameFilter,
      ...schemaFilter,
      ...dbFilter,
      ...tagFilter,
      totalCostFilter,
      insertionCostFilter,
      clusteringCostFilter,
      storageCostFilter,
      sortOrder,
      sortAttribute,
    ],
    queryFn: () => getDatasetList(getRequestBody()),
  });

  const { data: filters, isLoading: isFiltersLoading } = useQuery({
    queryKey: [GET_DATASET_LIST_FILTERS, page],
    queryFn: () => getDatasetListFilters(),    
  });

  const tableNameFilterDispTag = useMemo(() => {
    return filters?.rks?.reduce((acc, { rk, name }) => {
      if (tableNameFilter.includes(rk)) {
        acc[name] = rk;
      }
      return acc;
    }, {}) || {};
  }, [tableNameFilter, filters]);

  const schemaFilterDispTag = useMemo(() => {
    return filters?.rks?.reduce((acc, { rk, path, name }) => {
      const schema = path.split(".")[1];
      if (schemaFilter.includes(schema)) {
        acc[schema] = schema;
      }
      return acc;
    }, {});
  }, [schemaFilter, filters]);

  const dbFilterDispTag = useMemo(() => {
    return (filters?.rks || []).reduce((acc, { rk, path, name }) => {
      const db = path.split(".")[0];
      if (dbFilter.includes(db)) {
        acc[db] = db;
      }
      return acc;
    }, {});
  }, [dbFilter, filters]);

  const handleTotalCostSortChange = (so) => {
    if (so) {
      setSortAttribute("total_cost");
      setSortOrder((prevSortOrder) => ({
        ...prevSortOrder,
        total_cost: so,
      }));
    }
  };

  const handleInsertionCostSortChange = (so) => {
    if (so) {
      setSortAttribute("insertion_cost");
      setSortOrder((prevSortOrder) => ({
        ...prevSortOrder,
        insertion_cost: so,
      }));
    }
  };

  const handleStorageCostSortChange = (so) => {
    if (so) {
      setSortAttribute("storage_cost");
      setSortOrder((prevSortOrder) => ({
        ...prevSortOrder,
        storage_cost: so,
      }));
    }
  };

  const handleClusteringCostSortChange = (so) => {
    if (so) {
      setSortAttribute("clustering_cost");
      setSortOrder((prevSortOrder) => ({
        ...prevSortOrder,
        clustering_cost: so,
      }));
    }
  };

  const tableNameFilterOptions = useMemo(
    () =>
      filters?.rks?.map((t) => ({
        label: t.name,
        value: t.rk,
      })),
    [filters]
  );

  const schemaFilterOptions = useMemo(
    () =>
      filters?.rks
        ?.map((t) => t.path.split(".")[1])
        ?.filter((value, index, self) => self.indexOf(value) === index)
        ?.map((t) => ({
          label: t,
          value: t,
        })),
    [filters]
  );

  const dbFilterOptions = useMemo(
    () =>
      filters?.rks
        ?.map((t) => t.path.split(".")[0])
        ?.filter((value, index, self) => self.indexOf(value) === index)
        ?.map((t) => ({
          label: t,
          value: t,
        })),
    [filters]
  );

  if (isTablesLoading || isFiltersLoading) return <RelativeComponentLoader />;
  const header = [
    {
      id: 1,
      label: "Table Names",
      filter: {
        filterType: "multiDropdown",
        options: [
          {
            value: "table-name-filter",
            label: "Table Name",
            subComponent: (close) => {
              return (
                <StaticFilterDropdown
                  id="table-name-filter"
                  label={"Table Name"}
                  value={tableNameFilter || []}
                  options={tableNameFilterOptions}
                  onChange={(value) => {
                    updateSearchParamsByKey({ tableNameFilter: value });
                    close();
                  }}
                />
              );
            },
          },
          {
            value: "schema-filter",
            label: "Schema",
            subComponent: (close) => {
              return (
                <StaticFilterDropdown
                  id="schema-filter"
                  label={"Schema"}
                  value={schemaFilter || []}
                  options={schemaFilterOptions}
                  onChange={(value) => {
                    updateSearchParamsByKey({ schemaNameFilter: value });
                    close();
                  }}
                />
              );
            },
          },
          {
            value: "db-filter",
            label: "Database",
            subComponent: (close) => {
              return (
                <StaticFilterDropdown
                  id="db-filter"
                  label={"Database"}
                  value={dbFilter || []}
                  options={dbFilterOptions}
                  onChange={(value) => {
                    updateSearchParamsByKey({ databaseNameFilter: value });
                    close();
                  }}
                />
              );
            },
          },
        ],
      },
    },
    {
      id: 2,
      label: "Annualized Total Cost",
      sort: {
        onChange: handleTotalCostSortChange,
        value: sortOrder.total_cost,
      },
      filter: {
        searchKey: "totalCostFilter",
        filterType: "text",
        value: totalCostFilter,
        placeHolder: `Specify cost in ${currencySymbol}`,
        label: "Annualized Total Cost",
      },
    },

    {
      id: 3,
      label: "Annualized Insertion Cost",
      sort: {
        onChange: handleInsertionCostSortChange,
        value: sortOrder.insertion_cost,
      },
      filter: {
        searchKey: "insertionCostFilter",
        filterType: "text",
        value: insertionCostFilter,
        placeHolder: `Specify cost in ${currencySymbol}`,
        label: "Annualized Insertion Cost",
      },
    },

    {
      id: 4,
      label: (
        <div className="d-flex justify-content-between align-items-center">
          Annualized Storage Cost
          <UITooltip
            content="(ACTIVE_BYTES + TIME_TRAVEL_BYTES + FAILSAFE_BYTES + RETAINED_FOR_CLONE_BYTES) / (1024 ^4) * 23 * 12"
            placement="top"
          >
            <div className="d-flex align-items-center">
              <InfoGray />
            </div>
          </UITooltip>
        </div>
      ),
      sort: {
        onChange: handleStorageCostSortChange,
        value: sortOrder.storage_cost,
      },
      filter: {
        searchKey: "storageCostFilter",
        filterType: "text",
        value: storageCostFilter,
        placeHolder: `Specify cost in ${currencySymbol}`,
        label: "Annualized Storage Cost",
      },
    },

    {
      id: 5,
      label: "Annualized Clustering Cost",
      sort: {
        onChange: handleClusteringCostSortChange,
        value: sortOrder.clustering_cost,
      },
      filter: {
        searchKey: "clusteringCostFilter",
        filterType: "text",
        value: clusteringCostFilter,
        placeHolder: `Specify cost in ${currencySymbol}`,
        label: "Annualized Clustering Cost",
      },
    },

    {
      id: 6,
      label: "Insights",
      filter: {
        searchKey: "tagFilter",
        filterType: "dropdown",
        value: tagFilter,
        options: filters.tag_rks.map((t) => ({
          label: t,
          value: t,
        })),
      },
    },
  ];
  if (
    tables.total === 0 &&
    !(
      totalCostFilter ||
      insertionCostFilter ||
      storageCostFilter ||
      clusteringCostFilter ||
      (tableNameFilter && tableNameFilter.length > 0) ||
      (schemaFilter && schemaFilter.length > 0) ||
      (dbFilter && dbFilter.length > 0) ||
      tagFilter
    )
  ) {
    return <NoDatasets />; // only returned if no tables were found without applying any of the filters.
  }
  return (
    <>
      <div
        className={classNames(
          styles.dataset_list,
          "p-3 d-flex flex-column bg-white border-radius-bottom"
        )}
      >
        <TableHeader
          numTables={tables.total}
          tableFilters={getRequestBody()}
          tableNameFilterDispTag={tableNameFilterDispTag}
          schemaFilterDispTag={schemaFilterDispTag}
          dbFilterDispTag={dbFilterDispTag}
        />
        <ListingTable
          header={header}
          items={tables.items}
          idKey={(item) => item.rk.rk}
          onItemClick={onTableClick}
          resetPage={() => setPage(0)}
          templateColumns="6fr 1fr 1fr 1fr 1fr 4fr"
          rowRender={(item) => (
            <>
              <div className="cursor-pointer">
                <div
                  className={classNames(
                    styles.word_break,
                    "text-primary condense-text"
                  )}
                >
                  {getIconByDatastoreType("snowflake")}
                  {item.rk.name}
                </div>
                <div
                  className={styles.word_break}
                  data-tooltip-id={"table-" + item.rk.rk}
                >
                  <TablePath text={item.rk.path} maxLength={100} />
                  <CopyIconButton
                    value={item.rk.path}
                    color="rgba(8, 34, 71, 0.5)"
                    isLight
                  />
                </div>
                <Tooltip
                  id={"table-" + item.rk.rk}
                  className={styles.sync_disabled_tooltip}
                >
                  <div className="d-flex flex-column align-items-left gap-xs justify-content-center">
                    <div>{item.rk.path}</div>
                  </div>
                </Tooltip>
              </div>
              <div className={classNames(styles.word_break, "condense-text")}>
                {formatNumber(item.total_cost, { currency })}
              </div>
              <div className={classNames(styles.word_break, "condense-text")}>
                {formatNumber(item.insertion_cost, { currency })}
              </div>
              <div className={classNames(styles.word_break, "condense-text")}>
                {formatNumber(item.storage_cost, { currency })}
              </div>
              <div className={classNames(styles.word_break, "condense-text")}>
                {formatNumber(item.clustering_cost, { currency })}
              </div>
              <ColorInsightTags tags={item.tag_rks} />
            </>
          )}
        />
        <Paginate
          itemCount={tables.total}
          page={page}
          pageSize={tables.size}
          numPages={tables.pages}
          onPageClick={setPage}
        />
      </div>
      {/* <SidebarModal
          isOpen={selectedColumn !== ""}
          toggleModal={() => setSelectedColumn("")}
          width="600"
        >
          <ColumnsAccessedSidebar columnObj={selectedColumn.column} />
        </SidebarModal> */}
    </>
  );
};

export default DatasetsList;
