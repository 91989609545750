import { Col, Container, Row, Select, Tooltip } from "@uicore";
import styles from "../styles.module.scss";
import {
  ResourceTagColors,
  STATUS_OPTIONS,
} from "../../../pages/Explorer/constants";
import { useUsers } from "../../../helpers/hooks";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import ResourceTag from "../../../pages/Explorer/components/ResourceTag/ResourceTag";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getUser } from "@/services/auth";
import StatusAssignerDropdown from "@/pages/Explorer/components/StatusAssignerDropdown/StatusAssignerDropdown";
import OwnerAssignerDropdown from "@/pages/Explorer/components/OwnerAssignerDropdown/OwnerAssignerDropdown";
import EffortTag from "@/pages/Explorer/components/EffortTag/EffortTag";
import {
  getEffortLabel,
  getMoneySavings,
  getOpportunityStatus,
} from "@/pages/Explorer/utils";
import { useNavigate } from "react-router-dom";
import {
  getOpportunitiesRedirection,
  getOpportunityUpdates,
  getScheduledEventUpdates,
} from "@/helpers/apis";
import { useAppState } from "@/modules/app/useAppContext";
import { Tab } from "@/Components/Tab";
import { RelativeComponentLoader } from "@/Components/Loader";
import { EmptyScreen } from "@/pages/Warehouse/misc";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import {
  DismissedIcon,
  ImplementedIcon,
  ActiveIcon,
  AssignedIcon,
  BulkActionIcon,
  UnassignedIcon,
  InfoGray,
} from "@assets/icons";
import { ScrollContainer } from "@/Components/ScrollContainer";

const GET_OPPORTUNITY_UPDATES = "GET_OPPORTUNITY_UPDATES";
const GET_SCHEDULED_EVENTS_UPDATES = "GET_SCHEDULED_EVENTS_UPDATES";

export const getResourceOpportunityUrl = ({
  url,
  resource_uri,
  resource_type,
  opportunity_id,
  startDate,
  endDate,
}) => {
  const searchParams = {
    start_date: startDate?.toISOString(),
    end_date: endDate?.toISOString(),
    opportunityId: opportunity_id,
  };
  if (resource_type === 2) searchParams["tab"] = 2;
  return `${url}?${new URLSearchParams(searchParams).toString()}`;
};

const Resource = ({ resources, startDate, endDate }) => {
  const navigate = useNavigate();
  const [tooltipContent, setTooltipContent] = useState({});
  const { mutate } = useMutation(getOpportunitiesRedirection, {
    onSuccess: (data, variables) => {
      if (!data.url) {
        setTooltipContent((prev) => ({
          ...prev,
          [variables?.resource_uri]: "Resource not found",
        }));
        return;
      }
      navigate(
        getResourceOpportunityUrl({
          ...variables,
          url: data.url,
          startDate,
          endDate,
        })
      );
    },
  });
  const [shownResources, setShownResources] = useState(resources.slice(0, 3));
  const [expanded, setExpanded] = useState(false);

  return (
    <div className={styles.resources}>
      {shownResources.map((resource, i) => (
        <Tooltip
          content={tooltipContent[resource.resource_uri]}
          key={resource.resource_uri}
          placement="top"
          isOpen={true}
        >
          <ResourceTag
            resource={resource}
            index={i}
            onClick={() => mutate(resource)}
          />
        </Tooltip>
      ))}
      {resources.length > 3 && (
        <div
          className={styles.more}
          onClick={(e) => {
            e.stopPropagation();
            setExpanded(!expanded);
            setShownResources(expanded ? resources.slice(0, 3) : resources);
          }}
        >
          {expanded ? "Less" : "More"}
        </div>
      )}
    </div>
  );
};

const OpportunityInfo = ({
  opportunity,
  startDate,
  endDate,
  savingsInfo,
  extraContent,
}) => {
  const { currency, currencySymbol } = useAppState();
  const [assignedStatus, setAssignedStatus] = useState(
    getOpportunityStatus(opportunity?.status)
  );
  const [assignedOwner, setAssignedOwner] = useState(
    Number(opportunity?.owner)
  );
  return (
    <>
      <Row>
        <Row>
          <Col>
            <h6>Status</h6>
            <StatusAssignerDropdown
              opportunity_id={opportunity?.opportunity_id}
              assignedStatus={assignedStatus}
              setAssignedStatus={setAssignedStatus}
              startDate={startDate}
              endDate={endDate}
            />
          </Col>
          <Col>
            <h6>Effort</h6>
            <EffortTag effort={getEffortLabel(opportunity.effort)} />
          </Col>
          <Col>
            <h6>First Detected</h6>
            <div>{dayjs(opportunity.first_seen).fromNow()} ago</div>
          </Col>
        </Row>

        <Row>
          <Col>
            <h6>Owner</h6>
            <OwnerAssignerDropdown
              opportunity_id={opportunity?.opportunity_id}
              assignedOwner={assignedOwner}
              setAssignedOwner={setAssignedOwner}
              startDate={startDate}
              endDate={endDate}
            />
          </Col>
          <Col>
            <h6>Time Savings</h6>
            <div>{opportunity.time_savings}</div>
          </Col>
          <Col>
            <h6>Last Detected</h6>
            <div>{dayjs(opportunity.last_seen).fromNow()} old</div>
          </Col>
        </Row>

        <Row>
          <Col></Col>
          <Col>
            <h6>Money Savings</h6>
            <div className="d-flex gap-xs align-items-center">
              {getMoneySavings(
                opportunity?.money_savings?.min,
                opportunity?.money_savings?.max,
                currency
              )}
              {String(opportunity?.status) === "2" &&
                savingsInfo?.start_date &&
                savingsInfo?.end_date &&
                savingsInfo?.altimate_estimated_cost_saving && (
                  <Tooltip
                    content={
                      <div>
                        <div>
                          Start Date:{" "}
                          {dayjs(savingsInfo?.start_date).format("DD/MM/YYYY")}
                        </div>
                        <div>
                          End Date:{" "}
                          {dayjs(savingsInfo?.end_date).format("DD/MM/YYYY")}
                        </div>
                        <div>
                          Estimated Savings: {currencySymbol}
                          {savingsInfo?.altimate_estimated_cost_saving} per day
                        </div>
                      </div>
                    }
                    placement="top"
                  >
                    <InfoGray />
                  </Tooltip>
                )}
            </div>
          </Col>
          <Col>
            <h6>Count</h6>
            <div>{opportunity.count}</div>
          </Col>
        </Row>
      </Row>
      <div className="divider mt-2 mb-2" />
      <div className="d-flex justify-content-between  w-100">
        <h6>Resources</h6>
        <Resource
          resources={opportunity?.resources}
          startDate={startDate}
          endDate={endDate}
        />
      </div>
      {extraContent && (
        <div className="mt-2">
          {Object.entries(extraContent).map(([label, value], index) => (
            <>
              <div className="divider mt-2 mb-2" />
              <div key={index} className="d-flex gap-xl mt-2">
                <h6>{label}</h6>
                <div>{value}</div>
              </div>
            </>
          ))}
        </div>
      )}
    </>
  );
};

const timeline_contentStyle = {
  fontFamily: "Lexend",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "15px",
  color: "#082247",
  display: "flex",
  alignItems: "center",
  marginLeft: "150px",
  padding: "14px",
  gap: "10px",
  border: "1px solid #E7E8EA",
  boxShadow: "none",
  borderRadius: "12px",
};

const iconStyle = {
  width: "40px",
  height: "40px",
  display: "flex",
  background: "white",
  boxShadow: "none",
  border: "1px solid #E7E8EA",
  padding: "5px",
  marginTop: "10px",
};

const TimelineEventIconWithDate = (owner, status, eventTime) => {
  const isoDatetime = dayjs(eventTime);
  const formattedDate = dayjs(isoDatetime).format("MMM DD, YYYY");
  const formattedTime = dayjs(isoDatetime).format("hh:mm A");

  let eventType = "";
  if (owner && status) {
    eventType = "bulk";
  } else if (owner === "Un-assigned") {
    eventType = "unassigned";
  } else if (owner) {
    eventType = "assigned";
  } else if (status === "Dismissed") {
    eventType = "dismissed";
  } else if (status === "Implemented") {
    eventType = "implemented";
  } else if (status === "Active") {
    eventType = "active";
  }

  switch (eventType) {
    case "bulk":
      return (
        <>
          <BulkActionIcon />
          <div className={styles.timeline_event_icon}>{formattedDate}</div>
          <div className={styles.timeline_event_icon_time}>{formattedTime}</div>
        </>
      );
    case "unassigned":
      return (
        <>
          <UnassignedIcon />
          <div className={styles.timeline_event_icon}>{formattedDate}</div>
          <div className={styles.timeline_event_icon_time}>{formattedTime}</div>
        </>
      );
    case "assigned":
      return (
        <>
          <AssignedIcon />
          <div className={styles.timeline_event_icon}>{formattedDate}</div>
          <div className={styles.timeline_event_icon_time}>{formattedTime}</div>
        </>
      );
    case "dismissed":
      return (
        <>
          <DismissedIcon />
          <div className={styles.timeline_event_icon}>{formattedDate}</div>
          <div className={styles.timeline_event_icon_time}>{formattedTime}</div>
        </>
      );
    case "implemented":
      return (
        <>
          <ImplementedIcon />
          <div className={styles.timeline_event_icon}>{formattedDate}</div>
          <div className={styles.timeline_event_icon_time}>{formattedTime}</div>
        </>
      );
    case "active":
      return (
        <>
          <ActiveIcon />
          <div className={styles.timeline_event_icon}>{formattedDate}</div>
          <div className={styles.timeline_event_icon_time}>{formattedTime}</div>
        </>
      );

    default:
      return (
        <>
          <BulkActionIcon />
          <div className={styles.timeline_event_icon}>{formattedDate}</div>
          <div className={styles.timeline_event_icon_time}>{formattedTime}</div>
        </>
      );
  }
};

const getOpportunityText = (data) => {
  const created_by = data.created_by;
  const owner = data.owner;
  const status = data.status;

  if (owner === "Un-assigned") {
    if (created_by) {
      return "The opportunity was un-assigned by " + created_by;
    } else {
      return "The opportunity was un-assigned";
    }
  }

  if (created_by && owner && status) {
    // A assigned the opportunity to B and changed the status to C
    return (
      created_by +
      " assigned the opportunity to " +
      owner +
      " and changed the status to " +
      status
    );
  } else if (created_by && owner) {
    // A assigned the opportunity to B
    return created_by + " assigned the opportunity to " + owner;
  } else if (created_by && status) {
    // A changed the status to B
    return created_by + " changed the status to " + status;
  } else if (owner && status) {
    // B was assigned the opportunity and the status was changed to C
    return (
      owner +
      " was assigned the opportunity and the status was changed to " +
      status
    );
  } else if (created_by) {
    // A created the opportunity
    return created_by + " created the opportunity";
  } else if (owner) {
    // B was assigned the opportunity
    return owner + " was assigned the opportunity";
  } else if (status) {
    // The status was changed to C
    return "The status was changed to " + status;
  }
};

const Activity = ({ opportunity_id, showScheduledEventsActivity }) => {
  if (showScheduledEventsActivity) {
    return <ScheduledEventsActivity schedule_id={opportunity_id} />;
  }

  const { data, isLoading } = useQuery({
    queryKey: [GET_OPPORTUNITY_UPDATES],
    queryFn: () => {
      return getOpportunityUpdates({ opportunity_id });
    },
  });

  if (isLoading) return <RelativeComponentLoader componentHeight={10} />;

  if (!data || !data.length)
    return (
      <EmptyScreen
        title="No activity yet"
        subTitle="No activity found for the selected opportunity."
      />
    );

  return (
    <div id="opportunityEventsActivity" className={styles.opportunityActivity}>
      <VerticalTimeline
        className={styles.vertical_timeline}
        layout={"1-column-left"}
        lineColor={"#E7E8EA"}
      >
        {data?.map((d) => (
          <VerticalTimelineElement
            key={d.opportunity_id}
            className={styles.vertical_timeline_element}
            contentStyle={timeline_contentStyle}
            contentArrowStyle={{
              display: "none",
            }}
            iconStyle={iconStyle}
            icon={TimelineEventIconWithDate(d.owner, d.status, d.timestamp)}
          >
            {getOpportunityText(d)}
          </VerticalTimelineElement>
        ))}
      </VerticalTimeline>
    </div>
  );
};

const scheduledEventsTimelineIconMap = {
  rejected: <UnassignedIcon />,
  approved: <AssignedIcon />,
  dismissed: <DismissedIcon />,
};

const TimelineScheduledEventIconWithDate = (owner, eventType, eventTime) => {
  const isoDatetime = dayjs(eventTime);
  const formattedDate = isoDatetime.format("MMM DD, YYYY");
  const formattedTime = isoDatetime.format("hh:mm A");

  return (
    <>
      {scheduledEventsTimelineIconMap[eventType] || <BulkActionIcon />}
      <div className={styles.timeline_event_icon}>{formattedDate}</div>
      <div className={styles.timeline_event_icon_time}>{formattedTime}</div>
    </>
  );
};

const getScheduledEventText = (data) => {
  const { event_type, comment, created_by, created_at } = data;

  const formattedDate = new Date(created_at).toLocaleString(); // Format the date as needed.

  return `${created_by} ${event_type} the schedule on ${formattedDate}`;
};

const ScheduledEventsActivity = ({ schedule_id }) => {
  const { data, isFetching } = useQuery({
    queryKey: [GET_SCHEDULED_EVENTS_UPDATES],
    queryFn: () => {
      return getScheduledEventUpdates({ schedule_id });
    },
  });

  if (isFetching) return <RelativeComponentLoader componentHeight={10} />;

  if (!data || !data.length)
    return (
      <EmptyScreen
        title="No activity yet"
        subTitle="No activity found for the selected schedule."
      />
    );

  return (
    <div id="scheduledEventsActivity" className={styles.opportunityActivity}>
      <VerticalTimeline
        className={styles.vertical_timeline}
        layout={"1-column-left"}
        lineColor={"#E7E8EA"}
      >
        <ScrollContainer>
          <div className={styles.scroll_content}>
            {data?.map((d) => (
              <VerticalTimelineElement
                key={d.event_id}
                className={styles.vertical_timeline_element}
                contentStyle={timeline_contentStyle}
                contentArrowStyle={{
                  display: "none",
                }}
                iconStyle={iconStyle}
                icon={TimelineScheduledEventIconWithDate(
                  d.created_by,
                  d.event_type,
                  d.created_at
                )}
              >
                {getScheduledEventText(d)}
              </VerticalTimelineElement>
            ))}
          </div>
        </ScrollContainer>
      </VerticalTimeline>
    </div>
  );
};

const OpportunitySummary = ({
  opportunity,
  startDate,
  endDate,
  savingsInfo,
  extraContent,
  showScheduledEventsActivity,
}) => {
  const tabs = [
    {
      label: "Info",
      component: (
        <OpportunityInfo
          opportunity={opportunity}
          startDate={startDate}
          endDate={endDate}
          savingsInfo={savingsInfo}
          extraContent={extraContent}
        />
      ),
    },
    {
      label: "Activity",
      component: (
        <Activity
          opportunity_id={opportunity?.opportunity_id}
          showScheduledEventsActivity={showScheduledEventsActivity}
        />
      ),
    },
  ];

  return (
    <Container
      className={styles.opportunitySummary}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Tab
        tabs={tabs}
        onSelectedTabChange={() => {}}
        tabStyle={"w-100"}
        pagedTab={false}
      />
    </Container>
  );
};

export { OpportunitySummary };
