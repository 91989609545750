import { z } from "zod";
import { WarehouseSizeMap } from "./Components/Scheduler/helper";

export const WarehouseFiltersSchema = z.object({
  tags: z.array(z.string()),
  cost: z.string().optional(),
  warehouse: z.array(z.string()),
  size: z.array(z.string()).optional(),
  autotune: z.array(z.string()),
});

export type WarehouseFilters = z.infer<typeof WarehouseFiltersSchema>;

export const WarehouseSizeSchema = z.enum(
  Object.keys(WarehouseSizeMap) as [string, ...string[]]
);

export type WarehouseSize = z.infer<typeof WarehouseSizeSchema>;

export const WarehouseConfigSchema = z.object({
  warehouse_size: WarehouseSizeSchema,
});

export type WarehouseConfig = z.infer<typeof WarehouseConfigSchema>;

export const RecurrencePatternSchema = z.object({
  days: z.array(z.string().optional()).optional(),
  end_date: z.string().optional(),
});

export type RecurrencePattern = z.infer<typeof RecurrencePatternSchema>;

export const ScheduleCreateRequestSchema = z.object({
  warehouse_name: z.string(),
  warehouse_configuration: WarehouseConfigSchema,
  start_time: z.string(),
  end_time: z.string(),
  schedule_type: z.string(),
  reason: z.string().nullable().optional(),
  recurrence_pattern: RecurrencePatternSchema.nullable().optional(),
});

export type ScheduleCreateRequest = z.infer<typeof ScheduleCreateRequestSchema>;

export const ScheduleUpdateRequestSchema = z.object({
  warehouse_configuration: WarehouseConfigSchema.optional(),
  start_time: z.string().optional(),
  end_time: z.string().optional(),
  reason: z.string().optional(),
  recurrence_pattern: RecurrencePatternSchema.optional(),
});

export type ScheduleUpdateRequest = z.infer<typeof ScheduleUpdateRequestSchema>;

export const ScheduleApprovalRequestSchema = z.object({
  reason: z.string().optional(),
});

export type ScheduleApprovalRequest = z.infer<
  typeof ScheduleApprovalRequestSchema
>;

export type ScheduleRejectionRequest = z.infer<
  typeof ScheduleApprovalRequestSchema
>;

export const ScheduleResponseSchema = z.object({
  schedule_id: z.number(),
  warehouse_name: z.string(),
  configuration: z.object({
    cluster: z.string().optional(), // Make cluster optional
    warehouse_size: z.string().optional(), // Make size optional
    tags: z.array(z.string()).default([]), // Default to an empty array
  }),
  start_time: z.string(),
  end_time: z.string(),
  schedule_type: z.string(),
  status: z.string(),
  reason: z.string(),
  created_by: z.number(),
  created_at: z.string(),
  updated_by: z.number().nullable().optional(),
  updated_at: z.string().nullable().optional(),
  recommendation: z
    .object({
      recommendation_id: z.number(),
      applied_schedule_id: z.number(),
      recommendation_data: z.object({}).passthrough(),
      created_at: z.string(),
      metrics: z.object({}).passthrough(),
    })
    .nullable()
    .optional(),
  defaults: z
    .object({
      warehouse_name: z.string(),
      configuration: z.object({}).passthrough(),
      default_schedule: z.object({}).passthrough().nullable().optional(),
    })
    .passthrough()
    .nullable()
    .optional(),

  recurrence_pattern: RecurrencePatternSchema.passthrough()
    .nullable()
    .optional(),
});

export type ScheduleResponse = z.infer<typeof ScheduleResponseSchema>;

export const GetScheduleResponseSchema = z.array(ScheduleResponseSchema);

export type GetScheduleResponse = z.infer<typeof GetScheduleResponseSchema>;