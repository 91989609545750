import { useState } from "react";
import styles from "./styles.module.scss";
import { default as SnoozeIcon } from "../../assets/icons/snooze.svg?react";
import { getScopeString } from "../../helpers/utils";
import classnames from "classnames";
import { RiFilter3Line } from "react-icons/ri";
import { FaSortUp, FaSortDown, FaSort } from "react-icons/fa";
import { IconContext } from "react-icons";
import { Dropdown } from "../../Components/Form";
import { capitalize } from "lodash";
import { BetterPopover } from "../../Components/Popover";
import { useMutation, useQueryClient } from "react-query";
import { postSnoozeIncident } from "../../helpers/backend_helper";
import { GET_INCIDENTS } from "../../services/filters";
import { isSnoozedFor1Day } from "./helper";
import { SortIcon } from "../../Components/Tables";

const Icon = ({ children }) => (
  <IconContext.Provider value={{ size: "1.2rem" }}>
    {children}
  </IconContext.Provider>
);

const IncidentList = ({
  incidents,
  sort,
  levelFilter,
  timeFilter,
  contractFilter,
  onIncidentClick,
}) => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation(postSnoozeIncident, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GET_INCIDENTS] });
    },
  });
  return (
    <div>
      <div className={styles.table}>
        <div className={styles.table_header}>
          <div className="d-flex justify-content-between align-items-center">
            <span>Incident ID</span>
            <SortIcon
              onChange={sort.onIncident}
              sort={sort.incidentSortValue}
            />
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <span>Policy Name</span>
            <SortIcon onChange={sort.onPolicy} sort={sort.policySortValue} />
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <span>Timestamp</span>
            <Dropdown
              id="timestamp-dropdown"
              value={timeFilter.value}
              options={timeFilter.options}
              onChange={timeFilter.onChange}
            >
              <Icon>
                <RiFilter3Line />
              </Icon>
            </Dropdown>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <span>Level</span>
            <Dropdown
              isMultiSelect
              id="level-dropdown"
              value={levelFilter.value}
              options={levelFilter.options}
              onChange={levelFilter.onChange}
              showDivider
            >
              <Icon>
                <RiFilter3Line />
              </Icon>
            </Dropdown>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <span>Scope</span>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <span>Contract</span>
            <Dropdown
              isMultiSelect
              id="contract-dropdown"
              value={contractFilter.value}
              options={contractFilter.options}
              onChange={contractFilter.onChange}
              label="Contract"
            >
              <Icon>
                <RiFilter3Line />
              </Icon>
            </Dropdown>
          </div>
          <div
            className={classnames(
              "d-flex justify-content-between align-items-center",
              styles.action
            )}
          >
            <span>Action</span>
          </div>
        </div>
        <div className={styles.table_body}>
          {incidents.map((item) => {
            const snoozeId = "snooze-" + item.id;
            return (
              <div
                key={item.id}
                className={classnames(styles.table_row, styles.cursor_pointer)}
                onClick={(e) => {
                  e.preventDefault();
                  onIncidentClick(item.id);
                }}
              >
                <div className="text-primary">{item.id}</div>
                <div className={styles.break_word}>{item.policy_name}</div>
                <div>{item.timestamp}</div>
                <div
                  className={classnames("fw-semibold", {
                    [styles.orange]: item.level === "warning",
                    [styles.red]: item.level === "error",
                  })}
                >
                  {capitalize(item.level)}
                </div>
                <div
                  className={classnames(
                    styles.blue,
                    styles.scope,
                    styles.break_word
                  )}
                >
                  {item.scope.map(getScopeString).join(", ")}
                </div>
                <div className="fw-bold">{item.contract_name}</div>
                <div
                  className={classnames(
                    styles.action,
                    item.status === "active" ? styles.green : styles.grey
                  )}
                  onClick={(e) => e.stopPropagation()}
                >
                  <SnoozeIcon id={snoozeId} />
                  <BetterPopover
                    target={snoozeId}
                    hideArrow
                    placement="left-start"
                  >
                    {({ close }) => (
                      <ul className={styles.list}>
                        {[
                          {
                            value: "24h",
                            label: "24 hours",
                            predicate:
                              item.status === "snoozed" &&
                              isSnoozedFor1Day(item.snoozed_till),
                          },
                          {
                            value: "1w",
                            label: "1 week",
                            predicate:
                              item.status === "snoozed" &&
                              !isSnoozedFor1Day(item.snoozed_till),
                          },
                        ].map((i) => (
                          <li
                            key={i.value}
                            className={
                              i.predicate
                                ? styles.active
                                : ["active", "snoozed"].includes(item.status)
                                ? styles.enabled
                                : styles.disabled
                            }
                            onClick={(e) => {
                              e.stopPropagation();
                              mutate({ id: item.id, snoozed_till: i.value });
                              close();
                            }}
                          >
                            {i.label}
                          </li>
                        ))}
                      </ul>
                    )}
                  </BetterPopover>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export { IncidentList };
