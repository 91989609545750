import { StaticLineage } from "@/lib/altimate/altimate-components";

export enum Effort {
  LOW = "low",
  MEDIUM = "medium",
  HIGH = "high",
  ZERO = "zero",
}

export enum Status {
  ACTIVE = "active",
  IMPLEMENTED = "implemented",
  DISMISSED = "dismissed",
}

export enum Period {
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
}

// Types for individual components within the responses
export interface Resource {
  name: string;
  uri: string;
  resource_type: string;
}

// Request types
export interface GetOpportunitiesDataRequest {
  start_date: string; // Assuming this is in ISO format
  end_date: string; // Assuming this is in ISO format
  resource_name?: string | null;
  effort?: string[] | null; // List of effort levels as strings
  period: Period;
}

export interface GetOpportunitiesRedirectionRequest {
  resource_type: number | null;
  resource_uri: string | null;
}

export interface GetOpportunitiesResourceRequest {
  resource_uri: string;
  resource_type: ResourceType;
  start_date: string;
  end_date: string;
}

export interface GetOpportunityUpdatesRequest {
  opportunity_id: string;
}

export interface GetOpportunityUpdatesResponse {
  opportunity_id: string;
  timestamp: string; // Assuming this is in ISO format
  owner: string | null;
  status: string | null;
  created_by: string | null;
}

export interface UpdateOpportunitiesDataRequest {
  changes: Array<{
    opportunity_id: string;
    status: string | null | undefined;
    owner: string | null | undefined;
  }>;
  user_id: number | undefined;
  start_date: string; // Assuming this is in ISO format
  end_date: string; // Assuming this is in ISO format
}

export interface GetOpportunitiesSummaryRequest {
  start_date: string; // Assuming this is in ISO format
  end_date: string; // Assuming this is in ISO format
  resource_name?: string | null;
  effort?: Effort | null;
}

export interface MoneySavings {
  min: string;
  max: string;
}

// Response types
export interface GetOpportunitiesDataResponse {
  opportunity_id: string;
  resource_name: string;
  resource_type: string;
  opportunity_name: string;
  description?: string | null;
  last_seen: string; // Assuming this is in ISO format
  first_seen: string; // Assuming this is in ISO format
  count: number;
  total_count?: number | null;
  resources: Resource[];
  money_savings: MoneySavings;
  time_savings: string;
  effort: number;
  owner?: string | null;
  status: number;
  linenumber?: string;
  explanation_context?: any;
  savings_attribution?: any;
  metadata: { report: { actual_query_span: string; type: string; } };
}

export interface GetOpportunitiesResourceResponse {
  data: Array<GetOpportunitiesDataResponse>;
}

export interface GetOpportunitiesRedirectResponse {
  url: string;
}

export interface UpdateOpportunitiesDataResponse {
  ok: boolean;
}

export interface GetOpportunitiesSummaryResponse {
  label: string;
  value: string;
}

export interface GetOpportunitiesResourceNameFilterResponse {
  label: string;
  value: string;
}

export interface GetOpportunitiesUserFilterResponse {
  label: string;
  value: string;
}

export interface FilterResponse {
  label: string;
  value: string;
}

// Pagination response type for getOpportunities API
export interface Page<T> {
  items: T[];
  total: number;
  page: number;
  pages: number;
  size: number;
}

export interface GetOpportunitiesResourceNameFilterRequest {
  prefix: string;
}

export interface GetOpportunitiesUserFilterRequest {
  prefix: string;
}

export interface GetOpportunitiesWarehouseFilterRequest {
  prefix: string;
}

export interface GetQuerySQLLineageResponse {
  tableEdges: [string, string][];
  details: Record<string, any>;
  nodePositions: Record<string, [number, number]> | null;
  query_tags_meta: Record<string, any>;
  tags: Array<Record<string, any>>;
  is_lineage: boolean;
}

export enum ResourceType {
  QueryHash = 1,
  Table,
  Warehouse,
  User,
  Role,
}

export interface GetExportLineageItem {
  id: string;
  name: string;
  lineage_data: StaticLineage;
}
